import React from 'react';
import {ContractsTable} from "./ContractsTable/ContractsTable";
import {
    Alert,
    Button, Card, Col,
    Drawer,
    Form,
    Input,
    InputNumber,
    notification,
    PageHeader,
    Pagination,
    Row, Select, Spin,
    Tooltip
} from "antd";
import {PlusCircleOutlined, FileExcelOutlined, CloudDownloadOutlined, FilterOutlined} from "@ant-design/icons";
import instance from "../../../../Utils/API";
import ExcelToJson from "../../../../Utils/ExcelToJson";
import FileSaver from 'file-saver';


export default class AdminContracts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: '',
            visibleDrawer: false,
            multiple: false,
            isFilter: false,
            filterOptions: '',
        }
        const self = this;
        instance.get('/api/user/?page=0')
            .then(function (response) {
                self.setState({data: response.data.userDataPage})
            })
    }

    getUsers = () => {
        const self = this;
        instance.get(`/api/user/?page=0`)
            .then(function (response) {
                self.setState({data: response.data.userDataPage})
            })
    }


    onChangePagination = (page) => {
        const self = this;
        instance.get(`/api/user/?page=${page-1}${this.state.filterOptions !== '' ? `&${this.state.filterOptions}` : ''}`)
            .then(function (response) {
                self.setState({data: response.data.userDataPage})
            })
    }


    onFinish = (values) => {
        const self = this
        instance.post('/api/user', {
            address: values.address,
            apartmentNumber: values.apartmentNumber,
            contract: values.contract.toString(),
            fullName: values.fullName
            })
            .then(function (response) {
                notification['success']({
                    message: 'Пользователь успешно добавлен!',
                    description:
                        'Спасибо, что воспользовались нашим сервисом!',
                });
                self.setState({visibleDrawer: false})
                self.getUsers();
            })
            .catch(function (error) {
                notification['error']({
                    message: error.response.data.error,
                    description:
                        'Спасибо, что воспользовались нашим сервисом!',
                });
            })
    }

    onFinishFilter = (value) => {
        let searchParams = new URLSearchParams();

        for (let key in value) {
            if(value[key] !== undefined){
                searchParams.append(key, value[key])
            }
        }

        this.setState({filterOptions: searchParams.toString()})
        const self = this;
        instance.get(`/api/user/?page=0&${searchParams.toString()}`)
            .then(function (response) {
                self.setState({data: response.data.userDataPage})
            })
            .catch(function (error) {
                notification['error']({
                    message: error.response.data.error,
                    description:
                        'Спасибо, что воспользовались нашим сервисом!',
                });
            })
    }



    render() {
        const { Search } = Input;

        if(this.state.data === ''){
            return (
                <Row align="middle" justify="center">
                    <Spin tip="Загружаем данные..." />
                </Row>
            )
        } else{
            return (
                <div>
                    <PageHeader
                        className="site-page-header"
                        title="Список контрактов"
                        extra={[
                            <Search
                                placeholder="поиск"
                                onSearch={(value) => {
                                    const self = this;
                                    instance.get(`/api/user/?contract=${value}`)
                                        .then(function (response) {
                                            notification['success']({
                                                message: 'Поиск выполнен успешно!',
                                                description:
                                                    'Спасибо, что воспользовались нашим сервисом!',
                                            });
                                            self.setState({data: response.data.userDataPage})
                                        })
                                        .catch(function (error) {
                                            notification['error']({
                                                message: error.response.data.error,
                                                description:
                                                    'Спасибо, что воспользовались нашим сервисом!',
                                            });
                                        })
                                }}
                                enterButton style={{ width: 200 }}
                            />,

                            <Tooltip title="Фильтры">
                                <Button key="1" type={this.state.isFilter ? 'false' : "primary"} onClick={() => this.setState({isFilter: !this.state.isFilter})}>
                                    <FilterOutlined />
                                </Button>
                            </Tooltip>,

                            <Tooltip title="Массовая загрузка">
                                <Button key="1" type="primary" onClick={() => this.setState({visibleDrawer: true, multiple: true})}>
                                    <PlusCircleOutlined /> М
                                </Button>
                            </Tooltip>,

                            <Tooltip title="Добавление номера договора">
                                <Button key="1" type="primary" onClick={() => this.setState({visibleDrawer: true})}>
                                    <PlusCircleOutlined />
                                </Button>
                            </Tooltip>,

                            <Tooltip title="Выгрузка данных в Эксель">
                                <Button key="1" type="primary" icon={<CloudDownloadOutlined />} onClick={() => {
                                    var requestOptions = {
                                        method: 'GET',
                                        redirect: 'follow',
                                        headers: {
                                            'Authorization': localStorage.getItem('token')
                                        }
                                    };

                                    fetch("https://zero.obgaz.ru/api/user/downloadExcelData", requestOptions)
                                        .then(response => response.blob())
                                        .then(blob => {
                                            const url = window.URL.createObjectURL(blob);
                                            const a = document.createElement('a');
                                            a.style.display = 'none';
                                            a.href = url;
                                            a.download = 'Контакты.xlsx';
                                            document.body.appendChild(a);
                                            a.click();
                                            window.URL.revokeObjectURL(url);
                                        })
                                        .catch(error => console.log('error', error));

                                }}>
                                    <FileExcelOutlined />
                                </Button>
                            </Tooltip>,
                        ]}
                    />

                    {this.state.isFilter &&
                        <div className="site-card-border-less-wrapper" style={{marginTop: 20, marginBottom: 20}}>
                            <Card
                                title="Фильтры"
                            >
                                <Form
                                    id="form"
                                    name="basic"
                                    initialValues={{ remember: true }}
                                    onFinish={this.onFinishFilter}
                                    layout="vertical"
                                >
                                    <Row gutter={24}>
                                        <Col xs={24} md={6} xl={6}>
                                            <Form.Item
                                                label="Номер контракта пользователя"
                                                name="contract"
                                                rules={[{message: 'Пожалуйста, введите договор!' }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={6} xl={6}>
                                            <Form.Item
                                                label="ФИО пользователя"
                                                name="fullName"
                                                rules={[{message: 'Пожалуйста, введите Ф.И.О. пользователя!' }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={6} xl={6}>
                                            <Form.Item
                                                label="Кол-во записей на странице"
                                                name="size"
                                            >
                                                <InputNumber style={{width: '100%'}} />
                                            </Form.Item>
                                        </Col>
                                    </Row>


                                    <Row gutter={24} justify="end">
                                        <Col>
                                            <Form.Item>
                                                <Button htmlType="submit"
                                                        onClick={() => {
                                                            const self = this;

                                                            document.getElementById("form").reset();
                                                            instance.get('/api/user/?page=0&size=10')
                                                                .then(function (response) {
                                                                    self.setState({data: response.data.userDataPage})
                                                                })
                                                        }}
                                                >
                                                    Сбросить фильтр
                                                </Button>
                                            </Form.Item>
                                        </Col>

                                        <Col>
                                            <Form.Item>
                                                <Button type="primary" htmlType="submit">
                                                    Фильтр
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                </Form>
                            </Card>
                        </div>
                    }


                    <ContractsTable data={this.state.data} getUsers={this.getUsers}/>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                        <Pagination total={this.state.data.totalElements} pageSize={this.state.data.pageSize} showSizeChanger={false} onChange={this.onChangePagination} className="ant-table-pagination ant-table-pagination-left"/>
                    </div>

                    <Drawer
                        title='Добавление пользователя'
                        placement="right"
                        closable={false}
                        onClose={() => this.setState({visibleDrawer: false, multiple: false})}
                        visible={this.state.visibleDrawer}
                        width={500}
                    >
                        <div>
                            {this.state.multiple &&
                            <div>
                                <Alert
                                    message="Загружая файл, проверьте, что бы заголовки совпадали"
                                    description="['Номер', 'Наименование', 'Дом', 'Помещение']"
                                    type="info"
                                />
                                <div style={{marginTop: '50px'}}>
                                    <ExcelToJson headers={['Номер', 'Наименование', 'Дом', 'Помещение']} link='/api/user/multipleCreate' />
                                </div>
                            </div>
                            }
                            {!this.state.multiple &&
                            <Form
                                name="basic"
                                initialValues={{ remember: true }}
                                onFinish={this.onFinish}
                                layout={"vertical"}
                            >
                                <Form.Item
                                    label="Номер договора"
                                    name="contract"
                                    rules={[{
                                        required: true,
                                        type: 'number',
                                        message: 'Пожалуйста введите номер договора!'
                                    }]}
                                    style={{width: '100%'}}
                                >
                                    <InputNumber style={{width: '100%'}}/>
                                </Form.Item>
                                <Form.Item
                                    label="Ф.И.О."
                                    name="fullName"
                                    rules={[{
                                        required: true,
                                        message: 'Пожалуйста введите Ф.И.О.!'
                                    }]}
                                    style={{width: '100%'}}
                                >
                                    <Input style={{width: '100%'}}/>
                                </Form.Item>

                                <Form.Item
                                    label="Адрес"
                                    name="address"
                                    rules={[{
                                        required: true,
                                        message: 'Пожалуйста введите адрес!'
                                    }]}
                                    style={{width: '100%'}}
                                >
                                    <Input style={{width: '100%'}}/>
                                </Form.Item>

                                <Form.Item
                                    label="Номер квартиры"
                                    name="apartmentNumber"
                                    rules={[{
                                        required: true,
                                        message: 'Пожалуйста введите номер квартиры!'
                                    }]}
                                    style={{width: '100%'}}
                                >
                                    <Input style={{width: '100%'}}/>
                                </Form.Item>

                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                    >
                                        Добавить
                                    </Button>
                                </Form.Item>
                            </Form>
                            }
                        </div>
                    </Drawer>
                </div>
            );
        }
    }
}