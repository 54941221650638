import React from 'react';
import {Avatar, Card, Col, Row, Statistic} from "antd";
import EnterEvalueModal from "./EnterEvalueModal/EnterEvalueModal";
import moment from "moment";

export const Enumerators = props => {
    const [visibleModal, setVisibleModal] = React.useState(false);
    const [enumeratorData, setEnumeratorData] = React.useState('');

    const modalUnvisible = value => {
        setVisibleModal(value)
    }
    return(
        <Row gutter={16}>
            {
                props.userEnumerators.map((item) => (
                    <Col xs={24} md={12} xl={12} style={{marginTop: 10, marginBottom: 10}}>
                        <Card title={item.enumeratorType === 'GVS' ? 'Горячее водоснабжение' : 'Отопление'}
                              bordered
                              hoverable
                              onClick = {() => {setVisibleModal(true); setEnumeratorData(item)}}
                        >
                            <Row gutter={24}>
                                <Col xs={24} md={12} xl={12}>
                                    <Statistic title="Дата внесения" value={moment(item.lastTimeUpdated).format('DD.MM.YYYY')} suffix={'г.'}/>
                                </Col>
                                <Col xs={24} md={12} xl={12}>
                                    <Statistic title="Последние показания" value={item.enumeratorValue} suffix={'м2'}/>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                ))
            }
            <EnterEvalueModal visibleModal={visibleModal} modalUnvisible={modalUnvisible} enumeratorData={enumeratorData} getUserData={props.getUserData}/>
        </Row>
    )
}