import React from 'react';
import {Button, Card, Col, notification, Row, Spin, Statistic} from "antd";
import instance from "../../../../../Utils/API";

export default class AdminStats extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            payments: '',
            loadingPayments: true,
            users: '',
            loadingUsers: true,
        }
        const self = this;
        instance.get('/api/data/payments')
            .then(function (response) {
                self.setState({
                    payments: response.data,
                    loadingPayments: false
                })
            })

        instance.get('/api/data/users')
            .then(function (response) {
                self.setState({
                    users: response.data,
                    loadingUsers: false,
                })
            })

    }

    render() {
        if(this.state.loadingPayments === true || this.state.loadingUsers === true){
            return (
                <Spin tip="Загрузка данных"/>
            )
        }
        if(this.state.loadingPayments === false || this.state.loadingUsers === false){
            return(
                <Row gutter={16}>
                    <Col xs={24} md={12} xl={12} style={{marginTop: 10, marginBottom: 10}}>
                        <Card title={`Всего пользователей ${this.state.users.usersUnitedData.totalUsers}`} bordered hoverable>
                            <Row gutter={24}>
                                <Col xs={24} md={12} xl={12}>
                                    <Statistic title="Зарегистрированно"
                                               value={this.state.users.usersUnitedData.registeredUsers}
                                               suffix={'аккаунта(ов)'}
                                               valueStyle={{ color: '#3f8600' }}
                                    />
                                </Col>
                                <Col xs={24} md={12} xl={12}>
                                    <Statistic title="Не зарегистрированно"
                                               value={this.state.users.usersUnitedData.unregisteredUsers}
                                               suffix={'аккаунта(ов)'}
                                               valueStyle={{ color: '#cf1322' }}

                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col xs={24} md={12} xl={12} style={{marginTop: 10, marginBottom: 10}}>
                        <Card title={`Всего платежей ${this.state.payments.paymentsUnitedData.totalPayments}`} bordered hoverable>
                            <Row gutter={24}>
                                <Col xs={24} md={12} xl={12}>
                                    <Statistic title="Успешных"
                                               value={this.state.payments.paymentsUnitedData.successfulPayments}
                                               suffix={'платежей'}
                                               valueStyle={{ color: '#3f8600' }}
                                    />
                                </Col>
                                <Col xs={24} md={12} xl={12}>
                                    <Statistic title="Не успешные платежи"
                                               value={this.state.payments.paymentsUnitedData.unsuccessfulPayments}
                                               suffix={'платежей'}
                                               valueStyle={{ color: '#cf1322' }}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            )
        }
    }


}