import React from 'react';
import instance from "../../../../Utils/API";
import {Button, notification, PageHeader, Popconfirm, Row, Space, Spin, Table, Tag, Tooltip} from "antd";
import {DeleteOutlined, EditOutlined, FieldBinaryOutlined} from "@ant-design/icons";
import moment from "moment";
import {withRouter} from 'react-router-dom';

class AdminEditPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            blocksList: [],
        }
        const self = this;
        instance.get('/api/dynamicPages/blocks')
            .then(function (response) {
                self.setState({blocksList: response.data.blocksList})
            })
    }


    render() {
        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: 'Название',
                dataIndex: 'title',
                key: 'title',
            },
            {
                title: 'Дата создания',
                dataIndex: 'creationTime',
                key: 'creationTime',
                render: (item) => {
                    return(
                        <Tag color="green">
                            {moment(item).format('DD.MM.YYYY')}
                        </Tag>
                    )
                }
            },
            {
                title: 'Посследнее обновление',
                dataIndex: 'updateTime',
                key: 'updateTime',
                render: (item) => {
                    return(
                        <Tag color="magenta">
                            {moment(item).format('DD.MM.YYYY')}
                        </Tag>
                    )
                }
            },
            {
                title: 'Действия',
                key: '1',
                render: (item, record) => {
                    return(
                        <Space>
                            <Tooltip title="Редактирование">
                                <Button type="primary" shape="circle" icon={<EditOutlined />} onClick={() => {this.props.history.push(`/editpage/${record.id}`)}}/>
                            </Tooltip>
                        </Space>
                    )
                }
            },
        ]
        if(this.state.blocksList.length === 0){
            return (
                <Row align="middle" justify="center">
                    <Spin tip="Загружаем данные..." />
                </Row>
            )
        } else {
            return (
                <div>
                    <PageHeader
                        title="Редактирование страниц"
                    />
                    <Table dataSource={this.state.blocksList} columns={columns} pagination={false}/>
                </div>
            );
        }

    }
}

export default withRouter(AdminEditPage)