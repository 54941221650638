import React, {useState} from 'react';
import {Table, Input, Popconfirm, Form, Button, Tooltip} from 'antd';
import {QuestionCircleOutlined} from '@ant-design/icons';

const EditableCell = ({
                          editing,
                          dataIndex,
                          title,
                          inputType,
                          record,
                          index,
                          children,
                          ...restProps
                      }) => {
    const inputNode = inputType === 'number' ? <Input.Number /> : <Input />;
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{margin: 0}}
                    rules={[
                        {
                            required: true,
                            message: `Пожалуйста введите ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const GasificationEditableTable = (props) => {
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [editingKey, setEditingKey] = useState('');



    const handleAdd = () => {
        const newData = {
            key: data.length, // Вы можете выбрать более уникальный ключ в зависимости от ваших потребностей
            connectionPoint: "",
            plannedDesignTime: "",
            finalGasConsumption: "",
            maximumGasConsumption: "",
            previouslyConnectedGas: "",
        };
        setData([...data, newData]);
        edit(newData); // Включите редактирование новой записи
    };

    const isEditing = record => record.key === editingKey;

    const edit = record => {
        form.setFieldsValue(record);
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const save = async key => {
        try {
            const row = await form.validateFields();
            const newData = [...data];
            const index = newData.findIndex(item => key === item.key);

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {...item, ...row});
                setData(newData);
                props.setManyPointsValue(newData);
                setEditingKey('');
            } else {
                newData.push(row);
                props.setManyPointsValue(newData);
                setData(newData);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Произошла ошибка:', errInfo);
        }
    };

    const columns = [
        {
            title: 'Точка подключения (планируемая)',
            dataIndex: 'connectionPoint',
            editable: true,
        },
        {
            title: 'Планируемый срок проектирования, строительства и ввода в эксплуатацию объекта капитального строительства, вт ом числе по этапам и очередям (месяц, год)',
            dataIndex: 'projectImplementationTime',
            editable: true,
        },
        {
            title: <>Итоговая величина максимально часового расхода газа (мощности) газоиспользующего оборудования (подключаемого и ранее подключенного) (куб. метров в час) {' '}
                <Tooltip title={'Итоговая величина максимального часового' +
                  ' расхода газа (мощности) газоиспользующего оборудования ' +
                  '(подключаемого и ранее подключенного) является суммой величины максимального часового расхода газа (мощности) подключаемого газоиспользующего оборудования и ' +
                  'величины максимального часового расхода газа (мощности) газоиспользующего оборудования, ранее подключенного в данной точке подключения'}>
                    <QuestionCircleOutlined style={{color: '#9a9a9a'}}/></Tooltip></>,
            dataIndex: 'finalMaxHourlyGasFlow',
            editable: true,
        },
        {
            title: 'Величина максимального расхода газа (мощности) подключаемого газоиспользующего оборудования (куб. метров в час)',
            dataIndex: 'maxGasFlowConnectedEquipment',
            editable: true,
        },
        {
            title: 'Величина максимального часового расхода газа (мощности) газоиспользующего оборудования, ранее подключенного в данной точку (куб. метров в час)',
            dataIndex: 'maxGasFlowPrevConnectedEquipment',
            editable: true,
        },
        {
            title: 'Действие',
            dataIndex: 'Действие',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
            <a
                href="javascript:;"
                onClick={() => save(record.key)}
                style={{marginRight: 8}}
            >
              Сохранить
            </a>
            <Popconfirm title="Уверены в отмене?" onConfirm={cancel}>
              <a>Отмена</a>
            </Popconfirm>
          </span>
                ) : (
                    <a disabled={editingKey !== ''} onClick={() => edit(record)}>
                        Редактировать
                    </a>
                );
            },
        },
    ];

    const mergedColumns = columns.map(col => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: record => ({
                record,
                inputType: 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    return (
        <Form form={form} component={false}>
            <Button onClick={handleAdd} type="primary" style={{ marginBottom: 16 }}>
                Добавить строку
            </Button>
            <Table
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                bordered
                dataSource={data}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={{
                    onChange: cancel,
                }}
            />
        </Form>
    );
};

export default GasificationEditableTable;
