import React from 'react';
import {withRouter} from 'react-router-dom';
import instance from "../../../../../Utils/API";
import {
    Button,
    Card,
    Col,
    Divider, Drawer,
    Form,
    Input,
    InputNumber,
    notification,
    PageHeader,
    Row, Skeleton,
    Spin,
    Table,
    Tag
} from "antd";
import moment from "moment";
import MDEditor from "@uiw/react-md-editor";
import JobWithFiles from "../../../../../Utils/JobWithFiles";


class AdminEditNews extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageId: props.match.params.id,
            serviceData: null,
            title: null,
            imagePath: null,
            content: null,
        }
        this.editorRef = React.createRef();

        const self = this;
        instance.get(`/api/dynamicPages/news/${props.match.params.id}`)
            .then(function (response) {
                return(
                    self.setState({
                        serviceData: response.data.serviceData,
                        title: response.data.serviceData.title,
                        imagePath: response.data.serviceData.imagePath,
                        content: response.data.serviceData.content,
                    })
                )
            })
            // .catch(function (error) {
            //     notification["error"]({
            //         message: `${error.response.data.error}`,
            //         description:
            //             'Спасибо, что воспользовались нашим сервисом!',
            //     });
            // })
    }


    onFinish = value => {
        const self = this;

        instance.put('/api/dynamicPages/news', {id: this.props.match.params.id, content: this.state.content, imagePath: this.state.imagePath, title: this.state.title})
            .then(function (response) {
                notification["success"]({
                    message: `${response.data.message}`,
                    description:
                        'Спасибо, что воспользовались нашим сервисом!',
                });
                instance.get(`/api/dynamicPages/news/${self.props.match.params.id}`)
                    .then(function (response) {
                        self.setState({
                            serviceData: response.data.serviceData,
                            title: response.data.serviceData.title,
                            imagePath: response.data.serviceData.imagePath,
                            content: response.data.serviceData.content,
                        })
                    })
                    .catch(function (error) {
                        notification["error"]({
                            message: `${error.response.data.error}`,
                            description:
                                'Спасибо, что воспользовались нашим сервисом!',
                        });
                    })
            })
            .catch(function (error) {
                notification["error"]({
                    message: `${error.response.data.error}`,
                    description:
                        'Спасибо, что воспользовались нашим сервисом!',
                });
            })
    }


    render() {
        if(this.state.serviceData === null){
            return (
                <Row align="middle" justify="center">
                    <Spin tip="Загружаем данные..." />
                </Row>
            )
        } else {
            return(
                <div>
                    <PageHeader
                        title={this.state.serviceData.title}
                        onBack={() => this.props.history.push('/news')}
                        extra={[
                            <span>Последнее редактирование:</span>,
                            <Tag color="green">{moment(this.state.serviceData.updateTime).format('DD.MM.YYYY')}</Tag>
                        ]}
                    />
                    <Row justify="end">
                        <JobWithFiles />
                    </Row>
                    <Row gutter={12}>
                        <Col xs={24} md={12} xl={12}>
                            <Divider orientation="left" plain>
                                Редактрирование
                            </Divider>
                            <Form
                                name="basic"
                                onFinish={this.onFinish}
                                layout={"vertical"}

                            >
                                <Form.Item
                                    label="Название новости"
                                    name="title"
                                    rules={[{
                                    }]}
                                    style={{width: '100%'}}
                                >
                                    <Input style={{width: '100%'}}
                                                 defaultValue={this.state.serviceData.title}
                                                 onChange={(e) => {this.setState({title: e.target.value})}}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Изображение"
                                    name="imagePath"
                                    rules={[{
                                        message: 'Пожалуйста, загрузите изображение!',
                                    }]}
                                    style={{width: '100%'}}
                                >
                                    <Input style={{width: '100%'}}
                                           defaultValue={this.state.serviceData.imagePath}
                                           onChange={(e) => {this.setState({imagePath: e.target.value})}}

                                    />
                                </Form.Item>

                                <span>Описание</span>
                                <MDEditor
                                    value={this.state.content}
                                    preview = 'edit'
                                    onChange={(value) => {this.setState({content: value})}}
                                />
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        style={{marginTop: 20}}
                                    >
                                        Редактировать
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Col>
                        <Divider type="vertical" />
                        <Col xs={24} md={11} xl={11}>
                            <Divider orientation="left" plain>
                                Предпросмотр
                            </Divider>

                            <div className="site-card-border-less-wrapper">
                                <Row gutter={24}>
                                    <Col>
                                        <Card title={this.state.title}
                                              bordered={false}
                                              style={{ width: 300, height: '100%' }}
                                              hoverable
                                              cover={<img alt="example" src={`${this.state.imagePath}`} />}
                                        >
                                            <MDEditor.Markdown source={this.state.content} />
                                        </Card>
                                    </Col>

                                    <Col>
                                        <Card title="Другая новость..."
                                              bordered={false}
                                              style={{ width: 300, height: 500 }}
                                              hoverable
                                        >
                                            <Skeleton.Image />
                                            <Skeleton />
                                        </Card>
                                    </Col>
                                </Row>


                            </div>
                        </Col>
                    </Row>
                </div>
            )
        }
    }
}

export default withRouter(AdminEditNews)