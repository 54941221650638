import React from 'react';
import {Button, Drawer, Layout, Menu, Row} from "antd";
import {
    AuditOutlined, BankOutlined, BookOutlined, CloudSyncOutlined, ContactsOutlined,
    EditOutlined, FileDoneOutlined, FileSearchOutlined, LogoutOutlined,
    PieChartOutlined,
    ProfileOutlined, ReadOutlined, ReconciliationOutlined, SettingOutlined,
    SolutionOutlined, TeamOutlined, UnorderedListOutlined, CopyOutlined, MenuFoldOutlined
} from "@ant-design/icons";
import { useSignOut } from 'react-auth-kit'
import { useAuthUser } from 'react-auth-kit'

import { useHistory, useLocation } from "react-router-dom"
import instance from "../../Utils/API";
import {BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import { TabBar } from 'antd-mobile';
import {MainBody} from "../Pages/MainPage/MainBody/MainBody";
import {MainFooter} from "../Pages/MainPage/Footer/MainFooter";
import moment from "moment";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

export const Empty = ({children}) => {
    return(
        <div>
            {children}
        </div>
    )
}


export const WorkSpace = ({children}) => {
    const [collapsed, setCollapsed] = React.useState(false)
    const [selectedTab, setSelectedTab] = React.useState('redTab')
    const signOut = useSignOut();
    const auth = useAuthUser();
    const history = useHistory();
    const location = useLocation();

    return (
        <div>
            <BrowserView>
                <Layout style={{ minHeight: '100vh' }}>
                    <Sider collapsible collapsed={collapsed} onCollapse={() => setCollapsed(!collapsed)}>
                        <div className="logo" style={{marginLeft: 10}}/>
                        {(auth().role[0] === 'admin' ||  auth().role[0] === 'moderator') &&
                        <Menu theme="dark" mode="inline" defaultSelectedKeys={[`${location.pathname}`]}>
                            <Menu.Item key="/profile" icon={<PieChartOutlined />} onClick={() => history.push('/profile')}>
                                Дашборд
                            </Menu.Item>
                            <Menu.Item key="/contracts" icon={<TeamOutlined />} onClick={() => history.push('/contracts')}>
                                Договоры
                            </Menu.Item>
                            <Menu.Item key="/enumerators" icon={<SolutionOutlined />} onClick={() => history.push('/enumerators')}>
                                Счетчики
                            </Menu.Item>
                            <Menu.Item key="/payments" icon={<ProfileOutlined />} onClick={() => history.push('/payments')}>
                                Оплата
                            </Menu.Item>
                            <Menu.Item key="/editpage" icon={<EditOutlined />} onClick={() => history.push('/editpage')}>
                                Редактор страниц
                            </Menu.Item>
                            <Menu.Item key="/news" icon={<AuditOutlined />} onClick={() => history.push('/news')}>
                                Новости
                            </Menu.Item>
                            <Menu.Item key="/services" icon={<CopyOutlined />} onClick={() => history.push('/services')}>
                                Услуги
                            </Menu.Item>
                            <Menu.Item
                                key="7"
                                icon={<LogoutOutlined />}
                                style={{backgroundColor: '#ff0000', color: '#ffffff'}}
                                onClick={() => {
                                    const token = localStorage.getItem('token')
                                    signOut();
                                    localStorage.removeItem('token')
                                    window.location.reload();
                                    instance.post('/api/auth/logout', {token: token})
                                }}
                            >
                                Выход
                            </Menu.Item>
                        </Menu>
                        }

                        {auth().role[0] === 'user' &&
                        <Menu theme="dark" mode="inline" defaultSelectedKeys={[`${location.pathname}`]}>
                            <Menu.Item key="/profile" icon={<PieChartOutlined />} onClick={() => history.push('/profile')}>
                                Счетчики
                            </Menu.Item>
                            <Menu.Item key="/pay" icon={<BankOutlined />} onClick={() => history.push('/pay')}>
                                Оплата
                            </Menu.Item>
                            <Menu.Item
                                key="7"
                                icon={<LogoutOutlined />}
                                style={{backgroundColor: '#ff0000', color: '#ffffff'}}
                                onClick={() => {signOut(); window.location.reload()}}
                            >
                                Выход
                            </Menu.Item>
                        </Menu>
                        }

                    </Sider>
                    <Layout className="site-layout">
                        <Header>
                            <Menu theme="dark" mode="horizontal" style={{display: 'flex', justifyContent: 'flex-end'}} defaultSelectedKeys={[`${location.pathname}`]}>
                                <Menu.Item key="/company/about" icon={<BookOutlined />} onClick={() => {history.push('/company/about')}}>
                                    О КОМПАНИИ
                                </Menu.Item>
                                <Menu.Item key="/company/gas" icon={<CloudSyncOutlined />} onClick={() => {history.push('/company/gas')}}>
                                    СЖИЖЕННЫЙ ГАЗ
                                </Menu.Item>
                                <Menu.Item key="/company/services" icon={<ReconciliationOutlined />} onClick={() => {history.push('/company/services')}}>
                                    УСЛУГИ
                                </Menu.Item>
                                <Menu.Item key="/company/contacts" icon={<ContactsOutlined />} onClick={() => {history.push('/company/contacts')}}>
                                    КОНТАКТЫ
                                </Menu.Item>
                                <Menu.Item key="/company/news" icon={<ReadOutlined />} onClick={() => {history.push('/company/news')}}>
                                    НОВОСТИ
                                </Menu.Item>
                                <Menu.Item key="6" icon={<FileDoneOutlined />} onClick={() => window.location.assign('http://www.disclosure.ru/issuer/8601014059/')}>
                                    ОТЧЕТНОСТЬ
                                </Menu.Item>
                                <Menu.Item key="/company/formgasification" onClick={() => {history.push('/company/formgasification')}}>
                                    ГАЗИФИКАЦИЯ
                                </Menu.Item>
                                <Menu.SubMenu title={"Раскрытие информации"}>
                                    <Menu.Item key="/company/secret" icon={<FileSearchOutlined />} onClick={() => {history.push('/company/secret')}}>
                                        РАСКРЫТИЕ ИНФОРМАЦИИ
                                    </Menu.Item>
                                    <Menu.Item key="/company/secret/archive" icon={<FileSearchOutlined />} onClick={() => {history.push('/company/secret/archive')}}>
                                        Архив
                                    </Menu.Item>
                                </Menu.SubMenu>

                            </Menu>
                        </Header>
                        <Content style={{ margin: '20px 16px' }}>
                            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                                {children}
                            </div>
                        </Content>
                        <Footer style={{ textAlign: 'center' }}><a href='https://idea-tmn.ru' style={{color: '#000'}}>IDEЯ</a> {moment(new Date()).format('YYYY')}</Footer>
                    </Layout>
                </Layout>
            </BrowserView>

            <MobileView>
                <div style={{ position: 'fixed', height: '100%', width: '100%', top: 0 }}>
                    <TabBar
                        unselectedTintColor="#949494"
                        tintColor="#33A3F4"
                        barTintColor="white"
                        hidden={false}
                    >
                        <TabBar.Item
                            title="Профиль"
                            key="/profile"
                            icon={
                                <div style={{
                                    width: '22px',
                                    height: '22px',}}
                                >
                                    <PieChartOutlined style={{fontSize: '22px', color: '#929191'}}/>
                                </div>
                            }
                            selectedIcon={
                                <div style={{
                                    width: '22px',
                                    height: '22px',}}
                                >
                                    <PieChartOutlined style={{fontSize: '22px', color: 'rgb(51, 163, 244)'}}/>
                                </div>
                            }
                            selected={location.pathname === '/profile'}
                            onPress={() => history.push('/profile')}
                        >
                            {children}
                        </TabBar.Item>
                        <TabBar.Item
                            icon={
                                <div style={{
                                    width: '22px',
                                    height: '22px',}}
                                >
                                    <BankOutlined style={{fontSize: '22px', color: '#929191'}}/>
                                </div>
                            }
                            selectedIcon={
                                <div style={{
                                    width: '22px',
                                    height: '22px',}}
                                >
                                    <BankOutlined style={{fontSize: '22px', color: 'rgb(51, 163, 244)'}}/>
                                </div>
                            }
                            title="Оплата"
                            key="/pay"
                            selected={location.pathname === '/pay'}
                            onPress={() => history.push('/pay')}

                        >
                            {children}
                        </TabBar.Item>
                        <TabBar.Item
                            icon={
                                <div style={{
                                    width: '22px',
                                    height: '22px',}}
                                >
                                    <UnorderedListOutlined style={{fontSize: '22px', color: '#929191'}}/>
                                </div>
                            }
                            selectedIcon={
                                <div style={{
                                    width: '22px',
                                    height: '22px',}}
                                >
                                    <UnorderedListOutlined style={{fontSize: '22px', color: 'rgb(51, 163, 244)'}}/>
                                </div>
                            }
                            title="Меню"
                            key="/menu"
                            selected={location.pathname === '/menu'}
                            onPress={() => history.push('/menu')}

                        >
                            {children}
                        </TabBar.Item>
                    </TabBar>
                </div>
            </MobileView>
        </div>
    )
}

export const PageLayout = ({children}) => {
    const history = useHistory();
    const location = useLocation();
    const [visibleDrawer, setVisibleDrawer] = React.useState(false)
    return (
        <Layout>
            <BrowserView style={{height: '100%'}}>
                <Header>
                    <div className="logo"  onClick={() => {history.push('')}} style={{cursor: 'pointer'}}/>
                    <Menu theme="dark" mode="horizontal" style={{display: 'flex', justifyContent: 'flex-end'}} defaultSelectedKeys={[`${location.pathname}`]}>
                        <Menu.Item key="/company/about" icon={<BookOutlined />} onClick={() => {history.push('/company/about')}}>
                            О КОМПАНИИ
                        </Menu.Item>
                        <Menu.Item key="/company/gas" icon={<CloudSyncOutlined />} onClick={() => {history.push('/company/gas')}}>
                            СЖИЖЕННЫЙ ГАЗ
                        </Menu.Item>
                        <Menu.Item key="/company/services" icon={<ReconciliationOutlined />} onClick={() => {history.push('/company/services')}}>
                            УСЛУГИ
                        </Menu.Item>
                        <Menu.Item key="/company/contacts" icon={<ContactsOutlined />} onClick={() => {history.push('/company/contacts')}}>
                            КОНТАКТЫ
                        </Menu.Item>
                        <Menu.Item key="/company/news" icon={<ReadOutlined />} onClick={() => {history.push('/company/news')}}>
                            НОВОСТИ
                        </Menu.Item>
                        <Menu.Item key="6" icon={<FileDoneOutlined />} onClick={() => window.location.assign('http://www.disclosure.ru/issuer/8601014059/')}>
                            ОТЧЕТНОСТЬ
                        </Menu.Item>
                        <Menu.Item key="/company/formgasification" onClick={() => {history.push('/company/formgasification')}}>
                            ГАЗИФИКАЦИЯ
                        </Menu.Item>
                        <Menu.SubMenu title={"Раскрытие информации"}>
                            <Menu.Item key="/company/secret" icon={<FileSearchOutlined />} onClick={() => {history.push('/company/secret')}}>
                                РАСКРЫТИЕ ИНФОРМАЦИИ
                            </Menu.Item>
                            <Menu.Item key="/company/secret/archive" icon={<FileSearchOutlined />} onClick={() => {history.push('/company/secret/archive')}}>
                                Архив
                            </Menu.Item>
                        </Menu.SubMenu>
                    </Menu>
                </Header>
            </BrowserView>
            <MobileView style={{height: '100%'}}>
                <Header>
                    <Row justify="space-between" align="middle">
                        <div className="logo"  onClick={() => {history.push('')}} style={{cursor: 'pointer'}}/>
                        <MenuFoldOutlined
                            style={{color: '#ffffff', fontSize: '25px'}}
                            onClick={() => setVisibleDrawer(true)}
                        />
                    </Row>

                    <Drawer
                        title="Меню"
                        placement="right"
                        closable={true}
                        onClose={() => setVisibleDrawer(false)}
                        visible={visibleDrawer}
                        bodyStyle={{padding: 0}}
                    >
                        <Menu mode="inline" defaultSelectedKeys={[`${location.pathname}`]}>
                            <Menu.Item key="/company/about" icon={<BookOutlined />} onClick={() => {history.push('/company/about')}}>
                                О КОМПАНИИ
                            </Menu.Item>
                            <Menu.Item key="/company/gas" icon={<CloudSyncOutlined />} onClick={() => {history.push('/company/gas')}}>
                                СЖИЖЕННЫЙ ГАЗ
                            </Menu.Item>
                            <Menu.Item key="/company/services" icon={<ReconciliationOutlined />} onClick={() => {history.push('/company/services')}}>
                                УСЛУГИ
                            </Menu.Item>
                            <Menu.Item key="/company/contacts" icon={<ContactsOutlined />} onClick={() => {history.push('/company/contacts')}}>
                                КОНТАКТЫ
                            </Menu.Item>
                            <Menu.Item key="/company/news" icon={<ReadOutlined />} onClick={() => {history.push('/company/news')}}>
                                НОВОСТИ
                            </Menu.Item>
                            <Menu.Item key="6" icon={<FileDoneOutlined />} onClick={() => window.location.assign('http://www.disclosure.ru/issuer/8601014059/')}>
                                ОТЧЕТНОСТЬ
                            </Menu.Item>
                            <Menu.Item key="/company/formgasification" onClick={() => {history.push('/company/formgasification')}}>
                                ГАЗИФИКАЦИЯ
                            </Menu.Item>
                            <Menu.SubMenu title={"Раскрытие информации"}>
                                <Menu.Item key="/company/secret" icon={<FileSearchOutlined />} onClick={() => {history.push('/company/secret')}}>
                                    РАСКРЫТИЕ ИНФОРМАЦИИ
                                </Menu.Item>
                                <Menu.Item key="/company/secret/archive/archive" icon={<FileSearchOutlined />} onClick={() => {history.push('/company/secret/archive/archive')}}>
                                    Архив
                                </Menu.Item>
                            </Menu.SubMenu>
                        </Menu>
                    </Drawer>
                </Header>
            </MobileView>
            <Content>
                {children}
            </Content>

            <Footer className="MainFooter">
                <MainFooter />
            </Footer>
        </Layout>
    )
}
