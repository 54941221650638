import React from 'react';
import XLSX from 'xlsx';
import {Alert, Button, Form, notification, Progress, Spin, Upload} from "antd";
import instance from "./API";
import './ExcelToJson.css'
import {CloudUploadOutlined} from "@ant-design/icons";

export default class ExcelToJson extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            link: props.link,
            getLink: props.getLink,
            initialHeaders: props.headers,
            file: '',
            fileUpload: false,
            disabledButton: true,
            firstLine: '0',
            currentLine: '10000',
            endLine: '',
            percent: '0',
            loadings: false,
            result: '',
            wsNames: [],
            selected: '',
            wb: '',
            heading: '',
            findedList: '',
            disableUpload: false,
        }
    }


    changeValue(e) {
        e.stopPropagation();
        e.preventDefault();
        let file = e.target.files[0];
        if(file===undefined){
            return(
                notification['error']({
                    message: 'Ошибка! Совпадений по заголовкам в файле не найдено',
                })
            )
        }
        this.setState({ file });

        this.setState((state) => {
            return {
                loading: true,
                fileUpload: true,
                disableUpload: true,
            }
        });
        const reader = new FileReader();
        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: "binary", cellDates: true });
            const wsname = wb.SheetNames;
            for(let i = 0; i < wsname.length; i++){
                const data = XLSX.utils.sheet_to_json(wb.Sheets[wsname[i]], { header: 1 });
                if(data[0].length === this.state.initialHeaders.length){
                    for(let l = 0; l < data[0].length; l++){
                        if(data[0][l] === this.state.initialHeaders[l]){
                            this.setState((state) => {
                                return{
                                    findedList: wsname[i],
                                    disable: false,
                                    fileUpload: false,
                                    disableUpload: false,
                                }
                            })
                            const ws = wb.Sheets[wsname[i]];
                            const data = XLSX.utils.sheet_to_json(ws, { header: 1, defval: null });
                            this.setState((state) => {
                                return{
                                    heading: data[0]
                                }
                            })
                            let rowData = data.slice(1)
                            this.setState((state) => {
                                return{
                                    result: rowData,
                                    endLine: data.length,
                                    loading: false,
                                    disabledButton: false,
                                }
                            })
                            return true;
                        }
                        else{
                            this.setState((state) => {
                                return{
                                    disabledButton: false,
                                    fileUpload: false,
                                    disableUpload: false,
                                    loading: false
                                }
                            })
                            return (
                                notification['error']({
                                    message: 'Ошибка! Совпадений по заголовкам в файле не найдено',
                                })
                            )
                        }
                    }
                }
            }
        };
        reader.readAsBinaryString(file);
    }

    toServer(){
        this.setState((state) => {
            return{
                fileUploadedToServer: true,
                disabledButton: true,
                fileUpload: false,
                disableUpload: true,
            }
        })
        let result = this.state.result;
        let rowResult =  result.slice(this.state.firstLine, this.state.currentLine)
        let data = {}
        if(this.state.currentLine > this.state.endLine){
            this.setState((state) => {
                return{
                    currentLine: this.state.endLine
                }
            })
            data = {
                done: true,
                headers: this.state.heading,
                items: rowResult
            }
        }
        if(this.state.currentLine < this.state.endLine){
            data = {
                done: false,
                headers: this.state.heading,
                items: rowResult
            }
        }

        const self = this;
        instance.post(`${this.state.link}`, data)
            .then(function (response) {
                let firstLine = Number(self.state.firstLine) + 10000;
                let currentLine = Number(self.state.currentLine) + 10000;
                let percent = ((currentLine/self.state.endLine)*100).toFixed(2);
                self.setState((state) => {
                    return{
                        percent: percent
                    }
                })
                if(response.data.detail === 'pending'){
                    self.setState((state) => {
                        return{
                            firstLine: firstLine,
                            currentLine: currentLine,
                        }
                    })
                    return self.toServer();
                }
                if(response.data.detail === 'done'){
                    notification['error']({
                        message: 'Данные загружены!',
                    })
                    self.setState((state) => {
                        return{
                            loading: false
                        }
                    })
                }
            })
            .catch(function (response) {
                notification['error']({
                    message: 'Ошибка!',
                })
            })
    }


    render() {
        return(
            <div>
                {this.state.fileUploadedToServer &&
                    <div className="w-100">
                        <Progress percent={this.state.percent} status={this.state.percent >= 100 ? '' : 'active'}/>
                    </div>
                }
                {this.state.findedList.length > 3 &&
                    <div>
                        <Alert message={`Нужный лист найден! ${this.state.findedList}`} type="info" className="my-2"/>
                    </div>
                }
                {this.state.fileUpload &&
                    <div className="w-100">
                        <Spin className="mx-auto my-2" tip="Обрабатываем файл..." />
                    </div>
                }
                <label htmlFor="file-upload" className={this.state.disableUpload ? 'custom-file-upload-uploaded' : 'custom-file-upload' }>
                    <CloudUploadOutlined /> Выберите файл
                </label>
                <input
                    type="file"
                    id="file-upload"
                    className="form-control"
                    disabled={this.state.fileUploadedToServer}
                    style={{display: 'none'}}
                    accept=".xlsx, .xls"
                    ref="fileUploader"
                    onChange={this.changeValue.bind(this)}

                />


                <Button
                    type="primary"
                    loading={this.state.loading}
                    disabled={this.state.disabledButton}
                    onClick={() => {
                        this.toServer();
                    }}
                >
                    Отправить файл
                </Button>
            </div>
        )
    }
}