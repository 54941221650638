import React from 'react';
import {Button, Card, Col, Form, Input, notification, Row} from "antd";
import instance from "../../../../../Utils/API";
import {Link, useHistory} from "react-router-dom";
import {LockOutlined, MailOutlined, SnippetsOutlined} from "@ant-design/icons";

export const RegComponent = props => {

    const history = useHistory();

    const onFinish = (values) => {
        instance.post('/api/auth/register', values)
            .then(function (response) {
                notification['success']({
                    message: 'Регистрация прошла успешно!',
                    description:
                        'Спасибо, что воспользовались нашим сервисом!',
                });
                history.push('/')
            })
            .catch(function (error) {
                notification['error']({
                    message: error.response.data.error,
                    description:
                        'Проверьте правильность введенных данных!',
                });
            })
    }

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 24 },
    };

    const tailLayout = {
        wrapperCol: { offset: 8, span: 24 },
    };
    return(
        <Card
            title="Регистрация"
            className="AuthComponent"
            actions={[
                <div><Link to="/">Авторизация</Link></div>,
                <div><Link to="/changepassword">Восстановление пароля</Link></div>,
            ]}
        >
            <Form
                {...layout}
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
            >
                <Form.Item
                    name="contract"
                    rules={[{ required: true, message: 'Пожалуйста, введите номер договора!' }]}
                >
                    <Input
                        prefix={<SnippetsOutlined style={{color: '#a9a9a9'}} />}
                        placeholder="Номер договора"
                    />
                </Form.Item>

                <Form.Item
                    name="email"
                    rules={[
                        {
                            type: 'email',
                            message: 'Пожалуйста, проверьте правильность написания!',
                        },
                        {
                            required: true,
                            message: 'Пожалуйста введите почту!',
                        },
                    ]}
                >
                    <Input
                        prefix={<MailOutlined style={{color: '#a9a9a9'}} />}
                        placeholder="Электронная почта"
                    />
                </Form.Item>

                <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Пожалуйста, введите пароль!' }]}
                >
                    <Input
                        prefix={<LockOutlined style={{color: '#a9a9a9'}} />}
                        type="password"
                        placeholder="Пароль"
                    />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                        Регистрация
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    )

}