import React from 'react';
import {
    Alert,
    Button, Card, Col,
    Drawer,
    Form,
    Input,
    InputNumber,
    message,
    notification,
    PageHeader,
    Pagination, Row,
    Select, Space, Spin, Tooltip
} from "antd";
import instance from "../../../../Utils/API";
import {TableEnumerators} from "./TableEnumerators";
import {CloudDownloadOutlined, FilterOutlined, FileExcelOutlined, PlusCircleOutlined} from "@ant-design/icons";
import ExcelToJson from "../../../../Utils/ExcelToJson";

export default class AdminEnumerators extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: '',
            users: [],
            visibleDrawer: false,
            multiple: false,
            isFilter: false,
            filterOptions: '',
        }
        const self = this;
        instance.get('/api/enumerator/?page=0')
            .then(function (response) {
                self.setState({data: response.data.enumeratorDataPage})
            })
        instance.get('/api/user/?page=0&size=99999')
            .then(function (response) {
                self.setState({users: response.data.userDataPage})
            })

    }

    getEnumerators = () => {
        const self = this;
        instance.get(`/api/enumerator/?page=0`)
            .then(function (response) {
                self.setState({data: response.data.enumeratorDataPage})
            })
    }

    onChangePagination = (page) => {
        const self = this;
        instance.get(`/api/enumerator/?page=${page-1}${this.state.filterOptions !== '' ? `&${this.state.filterOptions}` : ''}`)
            .then(function (response) {
                self.setState({data: response.data.enumeratorDataPage})
            })
    }

    onFinish = (value) => {
        const self = this;
        instance.post('/api/enumerator', {contract: value.contract, type: value.type, serialNumber: value.serialNumber, mark: value.mark, model: value.model})
            .then(function (response) {
                notification['success']({
                    message: 'Счетчик успешно добавлен',
                    description:
                        'Спасибо, что воспользовались нашим сервисом!',
                });
                instance.get('/api/enumerator/?page=0&size=10')
                    .then(function (response) {
                        self.setState({data: response.data.enumeratorDataPage})
                    })
            })
            .catch(function (error) {
                notification['error']({
                    message: error.response.data.error,
                    description:
                        'Спасибо, что воспользовались нашим сервисом!',
                });
            })
    }


    onFinishFilter = (value) => {
        let searchParams = new URLSearchParams();

        for (let key in value) {
            if(value[key] !== undefined){
                searchParams.append(key, value[key])
            }
        }

        this.setState({filterOptions: searchParams.toString()})
        const self = this;
        instance.get(`/api/enumerator/?page=0&${searchParams.toString()}`)
            .then(function (response) {
                self.setState({data: response.data.enumeratorDataPage})
            })
            .catch(function (error) {
                notification['error']({
                    message: error.response.data.error,
                    description:
                        'Спасибо, что воспользовались нашим сервисом!',
                });
            })
    }


    render() {
        const { Search } = Input;

        if(this.state.data === ''){
            return (
                <Row align="middle" justify="center">
                    <Spin tip="Загружаем данные..." />
                </Row>
            )
        } else{
            return (
                <div>
                    <PageHeader
                        className="site-page-header"
                        title="Список счетчиков"
                        extra={[
                            <Search
                                placeholder="Поиск договора"
                                onSearch={(value) => {
                                    const self = this;
                                    instance.get(`/api/enumerator/?contract=${value}`)
                                        .then(function (response) {
                                            notification['success']({
                                                message: 'Поиск выполнен успешно!',
                                                description:
                                                    'Спасибо, что воспользовались нашим сервисом!',
                                            });
                                            self.setState({data: response.data.enumeratorDataPage})
                                        })
                                        .catch(function (error) {
                                            notification['error']({
                                                message: error.response.data.error,
                                                description:
                                                    'Спасибо, что воспользовались нашим сервисом!',
                                            });
                                        })
                                }}
                                enterButton style={{ width: 200 }}
                            />,
                            <Tooltip title="Фильтры">
                                <Button key="1" type={this.state.isFilter ? 'false' : "primary"} onClick={() => this.setState({isFilter: !this.state.isFilter})}>
                                    <FilterOutlined />
                                </Button>
                            </Tooltip>,
                            <Tooltip title="Массовая загрузка">
                                <Button key="1" type="primary" onClick={() => this.setState({visibleDrawer: true, multiple: true})}>
                                    <PlusCircleOutlined /> М
                                </Button>
                            </Tooltip>,

                            <Tooltip title="Добавление счетчика">
                                <Button key="1" type="primary" onClick={() => this.setState({visibleDrawer: true})}>
                                    <PlusCircleOutlined />
                                </Button>
                            </Tooltip>,
                            <Tooltip title="Выгрузка данных в Эксель">
                                <Button key="1" type="primary" icon={<CloudDownloadOutlined />} onClick={() => {
                                    var requestOptions = {
                                        method: 'GET',
                                        redirect: 'follow',
                                        headers: {
                                            'Authorization': localStorage.getItem('token')
                                        }
                                    };

                                    fetch("https://zero.obgaz.ru/api/enumerator/downloadExcelData", requestOptions)
                                        .then(response => response.blob())
                                        .then(blob => {
                                            const url = window.URL.createObjectURL(blob);
                                            const a = document.createElement('a');
                                            a.style.display = 'none';
                                            a.href = url;
                                            a.download = 'Счетчики.xlsx';
                                            document.body.appendChild(a);
                                            a.click();
                                            window.URL.revokeObjectURL(url);
                                        })
                                        .catch(error => console.log('error', error));

                                }}>
                                    <FileExcelOutlined />
                                </Button>
                            </Tooltip>,

                        ]}
                    />

                    {this.state.isFilter &&
                        <div className="site-card-border-less-wrapper" style={{marginTop: 20, marginBottom: 20}}>
                            <Card
                                title="Фильтры"
                            >
                                <Form
                                    id="form"
                                    name="basic"
                                    initialValues={{ remember: true }}
                                    onFinish={this.onFinishFilter}
                                    layout="vertical"
                                >
                                    <Row gutter={24}>
                                        <Col xs={24} md={6} xl={6}>
                                            <Form.Item
                                                label="Номер контракта пользователя"
                                                name="contract"
                                                rules={[{message: 'Пожалуйста, введите договор!' }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={6} xl={6}>
                                            <Form.Item
                                                label="Кол-во записей на странице"
                                                name="size"
                                            >
                                                <InputNumber style={{width: '100%'}} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={6} xl={6}>
                                            <Form.Item
                                                label="Тип счётчика"
                                                name="type"
                                                rules={[{message: 'Пожалуйста, выберите тип счётчика!' }]}
                                            >
                                                <Select style={{ width: '100%' }}>
                                                    <Select.Option value="gvs">ГВС</Select.Option>
                                                    <Select.Option value="heat">Отопление</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>


                                    <Row gutter={24} justify="end">
                                        <Col>
                                            <Form.Item>
                                                <Button htmlType="submit"
                                                        onClick={() => {
                                                            const self = this;

                                                            document.getElementById("form").reset();
                                                            instance.get('/api/user/?page=0&size=10')
                                                                .then(function (response) {
                                                                    self.setState({data: response.data.userDataPage})
                                                                })
                                                        }}
                                                >
                                                    Сбросить фильтр
                                                </Button>
                                            </Form.Item>
                                        </Col>

                                        <Col>
                                            <Form.Item>
                                                <Button type="primary" htmlType="submit">
                                                    Фильтр
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                </Form>
                            </Card>
                        </div>
                    }

                    <TableEnumerators data={this.state.data} getEnumerators={this.getEnumerators}/>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                        <Pagination total={this.state.data.totalElements} pageSize={this.state.data.pageSize} showSizeChanger={false}  onChange={this.onChangePagination} className="ant-table-pagination ant-table-pagination-left"/>
                    </div>

                    <Drawer
                        title='Добавление счетчика'
                        placement="right"
                        closable={false}
                        onClose={() => this.setState({visibleDrawer: false, multiple: false})}
                        visible={this.state.visibleDrawer}
                        width={500}
                    >
                        <div>
                            {this.state.multiple &&
                            <div>
                                <Alert
                                    message="Загружая файл, проверьте, что бы заголовки совпадали"
                                    description="['Наименование', 'Номер', 'Помещение', 'Тип', 'Серийный', 'Марка', 'Модель']"
                                    type="info"
                                />
                                <div style={{marginTop: '50px'}}>
                                    <ExcelToJson
                                        headers={['Наименование', 'Номер', 'Помещение', 'Тип', 'Серийный', 'Марка', 'Модель']}
                                        link='/api/enumerator/multipleCreate'/>
                                </div>
                            </div>
                            }
                            {!this.state.multiple &&
                            <Form
                                name="basic"
                                initialValues={{ remember: true }}
                                onFinish={this.onFinish}
                                layout={"vertical"}
                            >
                                {this.state.users.length !== 0 &&
                                <Form.Item
                                    label="Номер договора"
                                    name="contract"
                                    rules={[{ required: true, message: 'Пожалуйста введите номер договора!' }]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Пожалуйста введите номер договора"
                                        allowClear
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {
                                            this.state.users.content.map((item) => {
                                                return(
                                                    <Select.Option value={item.contractNumber}>{item.contractNumber}</Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                }
                                <Form.Item name="type" label="Тип счётчика" rules={[{ required: true }]}>
                                    <Select
                                        placeholder="Пожалуйста, выберите тип счётчика"
                                        allowClear
                                    >
                                        <Select.Option value="gvs">ГВС</Select.Option>
                                        <Select.Option value="heat">Отопление</Select.Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label="Серийный номер"
                                    name="serialNumber"
                                    rules={[{
                                        required: true,
                                        type: 'number',
                                        message: 'Пожалуйста введите Серийный номер!'
                                    }]}
                                    style={{width: '100%'}}
                                >
                                    <InputNumber style={{width: '100%'}}/>
                                </Form.Item>

                                <Form.Item
                                    label="Марка"
                                    name="mark"
                                    rules={[{
                                        required: true,
                                        message: 'Пожалуйста введите номер квартиры!'
                                    }]}
                                    style={{width: '100%'}}
                                >
                                    <Input style={{width: '100%'}}/>
                                </Form.Item>

                                <Form.Item
                                    label="Модель"
                                    name="model"
                                    rules={[{
                                        required: true,
                                        message: 'Пожалуйста введите номер квартиры!'
                                    }]}
                                    style={{width: '100%'}}
                                >
                                    <Input style={{width: '100%'}}/>
                                </Form.Item>

                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                    >
                                        Добавить
                                    </Button>
                                </Form.Item>
                            </Form>
                            }
                        </div>
                    </Drawer>
                </div>
            );
        }
    }
}