import React from 'react';
import {Area, AreaChart, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer} from "recharts";
import moment from "moment";
import {AimOutlined} from "@ant-design/icons";
import {WingBlank} from "antd-mobile";
import {Col, Row} from "antd";

export default class UserCharts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            enumeratorData: props.enumeratorData,
            enumeratorsOperations: props.enumeratorData.enumeratorList.map((item) => {
                const data = props.enumeratorData.enumeratorValueHistoryDataList.filter(id => id.enumeratorId === item.id)
                for(let i = 0; i < data.length; i++){
                    data[i].changeTime = moment(data[i].changeTime).format('DD.MM.YYYY')
                }
                return(
                    props.enumeratorData.enumeratorValueHistoryDataList.filter(id => id.enumeratorId === item.id)
                )
            }),
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props !== prevProps){
            this.setState({enumeratorData: this.props.enumeratorData})
        }
    }

    data = [
        {
            "name": "Page A",
            "uv": 4000,
            "pv": 2400,
            "amt": 2400
        },
    ]

    render() {
        return(
            <Row gutter={24}>
                {
                    this.state.enumeratorsOperations.map((item) => (
                            <Col xs={24} md={12} xl={12}>
                                <div style={{ width: '100%', height: 300 }}>
                                    <ResponsiveContainer>
                                        <AreaChart width={500} height={250} data={item}
                                                   margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                                            <defs>
                                                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                                                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                                                </linearGradient>
                                            </defs>
                                            <XAxis dataKey={'changeTime'} name="Дата"/>
                                            <YAxis />
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <Tooltip />
                                            <Area type="natural"
                                                  dataKey="value"
                                                  stroke="#8884d8"
                                                  fillOpacity={1}
                                                  fill="url(#colorUv)"
                                                  name="Показания"
                                            />
                                        </AreaChart>
                                    </ResponsiveContainer>
                                </div>
                            </Col>
                    ))
                }
            </Row>
        )
    }

}