import React from 'react';
import {Button, Card, Col, Form, Input, notification, Row} from "antd";
import './AuthComponent.css'
import API from "../../../../../Utils/API";
import jwt from 'jsonwebtoken';
import instance from "../../../../../Utils/API";
import { withSignIn } from 'react-auth-kit'
import {Link} from "react-router-dom";
import {LockOutlined, SnippetsOutlined} from "@ant-design/icons";
import axios from "axios";

class AuthComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dogovorNumber: null
        }
    }
    onFinish = (values) => {
        const self = this;
        axios.post('https://zero.obgaz.ru/api/auth/login', {login: values.contract, password: values.password})
            .then(function (response) {
                const barToken = response.data.token.split(' ');
                const decoded = jwt.decode(barToken[1])
                let exp = new Date(decoded.exp * 1000)
                let iat = new Date(decoded.iat * 1000)
                let tokenLifeTime = (exp - iat)/60000
                localStorage.setItem('token', response.data.token)
                instance.interceptors.request.use(function (config) {
                    config.headers.Authorization =  response.data.token;

                    return config;
                })

                if(self.props.signIn({
                    token: barToken[1],
                    expiresIn: tokenLifeTime,
                    tokenType: "Bearer",
                    authState: decoded,
                })){
                    // window.location.reload();
                    notification['success']({
                        message: 'Авторизация прошла успешно!',
                        description:
                            'Спасибо, что воспользовались нашим сервисом!',
                    });

                }
            })
            .catch(function (error) {
                if(error){
                    notification['error']({
                        message: error.response.data.error,
                        description:
                            'Пожалуйста, проверьте правильность введенных данных!',
                    });
                }
            })
    };


    render() {
        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 24 },
        };

        return(
            <Card
                title="Авторизация"
                className="AuthComponent"
                actions={[
                    <div><Link to="/register">Регистрация</Link></div>,
                    <div><Link to="/changepassword">Восстановление пароля</Link></div>,
                ]}
            >
                <Form
                    {...layout}
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                >
                    <Form.Item
                        name="contract"
                        rules={[{ required: true, message: 'Пожалуйста, введите номер договора!' }]}
                    >
                        <Input
                            prefix={<SnippetsOutlined style={{color: '#a9a9a9'}} />}
                            placeholder="Номер договора"
                            onChange={(e) => this.setState({dogovorNumber: e.target.value})}
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Пожалуйста, введите пароль!' }]}
                    >
                        <Input
                            prefix={<LockOutlined style={{color: '#a9a9a9'}} />}
                            type="password"
                            placeholder="Пароль"
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{width: '100%'}} disabled={!this.state.dogovorNumber || this.state.dogovorNumber !== 'admin@obgaz.ru'}>
                            Авторизация
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        )
    }
}

export default withSignIn(AuthComponent)