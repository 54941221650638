import React from 'react';
import {
    Button, Drawer, Form, Input, InputNumber, Modal, notification, Popconfirm, Select, Space,
    Table, Tag, Tooltip,

} from 'antd';
import {useHistory} from 'react-router-dom';
import { EditOutlined, DeleteOutlined, FieldBinaryOutlined } from '@ant-design/icons';
import moment from "moment";
import instance from "../../../../Utils/API";



export const TableEnumerators = props => {
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [visible, setVisible] = React.useState(false);
    const [enumeratorData, setEnumeratorData] = React.useState('');
    const [enumeratorValue, setEnumeratorValue] = React.useState('');

    const [form] = Form.useForm();
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: '1',
        },
        {
            title: 'Номер договора',
            dataIndex: 'contractNumber',
            key: '2',
        },
        {
            title: 'Тип',
            dataIndex: 'type',
            key: '3',
            render: (item) => {
                if (item === 'GVS'){
                    return(
                        <Tag color="green">ГВС</Tag>
                    )
                }else{
                    return(
                        <Tag color="magenta">Отопление</Tag>
                    )
                }
            }
        },
        {
            title: 'Серийный номер',
            dataIndex: 'serialNumber',
            key: '4',
        },
        {
            title: 'Марка',
            dataIndex: 'mark',
            key: '5',
        },
        {
            title: 'Модель',
            dataIndex: 'model',
            key: '6',
        },
        {
            title: 'Показания',
            dataIndex: 'value',
            key: '7',
        },
        {
            title: 'Дата внесения',
            dataIndex: 'updateTime',
            key: '8',
            render: (item) => {
                console.log(item)
                return(
                    <span>{moment(item).format('HH:mm DD.MM.YYYY')}</span>
                )
            }
        },
        {
            title: 'Действия',
            key: '9',
            render: (item) => {
                return(
                    <Space>
                        <Tooltip title="Редактирование">
                            <Button type="primary" shape="circle" icon={<EditOutlined />} onClick={() => {setVisible(true); setEnumeratorData(item)}}/>
                        </Tooltip>
                        <Tooltip title="Изменить значение">
                            <Button type="primary" shape="circle" icon={<FieldBinaryOutlined />} onClick={() => {setIsModalVisible(true); setEnumeratorData(item)}}/>
                        </Tooltip>
                        <Tooltip title="Удаление">
                            <Popconfirm title="Вы уверены?"
                                        okText="Да"
                                        cancelText="Нет"
                                        onConfirm={() => {
                                            instance.delete(`/api/enumerator/${item.id}`)
                                                .then(function (response) {
                                                    notification['success']({
                                                        message: 'Счетчик успешно удален',
                                                        description:
                                                            'Спасибо, что воспользовались нашим сервисом!',
                                                    });
                                                    props.getEnumerators();
                                                })
                                                .catch(function (error) {
                                                    notification['error']({
                                                        message: error.response.data.error,
                                                        description:
                                                            'Спасибо, что воспользовались нашим сервисом!',
                                                    });
                                                })
                                        }}
                            >
                                <Button type="primary" danger shape="circle" icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </Tooltip>
                    </Space>
                )
            }
        }
    ];


    const onFinish = (value) => {
        form.resetFields();
        instance.put('/api/enumerator', {id: enumeratorData.id, mark: value.mark, model: value.model, serialNumber: value.serialNumber, type: value.type})
            .then(function (response) {
                notification['success']({
                    message: 'Счетчик успешно изменен',
                    description:
                        'Спасибо, что воспользовались нашим сервисом!',
                });
                props.getEnumerators();
            })
            .catch(function (error) {
                notification['error']({
                    message: error.response.data.error,
                    description:
                        'Спасибо, что воспользовались нашим сервисом!',
                });
            })
    }

    return(
        <div>
            <Table
                bordered
                dataSource={props.data.content}
                columns={columns}
                pagination={false}
                rowKey="id"
            />

            <Modal title={`Редактирование значения счетчика ${enumeratorData.serialNumber}`}
                   visible={isModalVisible} onCancel={() => {setIsModalVisible(false); form.resetFields();}}
                   footer={[
                       <Button key="submit" type="primary" onClick={(e) => {
                           instance.put('/api/enumerator/updateValue', {id: enumeratorData.id, value: parseFloat(enumeratorValue.value)})
                               .then(function (response) {
                                   notification['success']({
                                       message: response.data.message,
                                       description:
                                           'Спасибо, что воспользовались нашим сервисом!',
                                   });
                                   props.getEnumerators();
                               })
                               .catch(function (error) {
                                   notification['error']({
                                       message: error.response.data.error,
                                       description:
                                           'Спасибо, что воспользовались нашим сервисом!',
                                   });
                               })
                       }}
                       >
                           Редактировать
                       </Button>,
                   ]}
            >
                <Form
                    name="ResetValueEnumerator"
                    onValuesChange={(value) => setEnumeratorValue(value)}
                    layout={"vertical"}
                    preserve={false}
                    form={form}
                >
                    <Form.Item
                        label="Новое значение счетчика"
                        name="value"
                        rules={[{
                            required: true,
                            message: 'Пожалуйста, укажите новое значени счетчика!'
                        }]}
                        style={{width: '100%'}}
                    >
                        <InputNumber
                            style={{width: '100%'}}
                            stringMode
                            min={0}
                        />
                    </Form.Item>
                </Form>
            </Modal>


            <Drawer
                title={`Редактирование счетчика ${enumeratorData.serialNumber}`}
                placement="right"
                closable={false}
                onClose={() => setVisible(false)}
                visible={visible}
                width={500}
            >
                <Form
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    layout={"vertical"}
                    fields={[
                        {
                            name: ["type"],
                            value: enumeratorData.type,
                        },
                        {
                            name: ["serialNumber"],
                            value: enumeratorData.serialNumber,
                        },
                        {
                            name: ["mark"],
                            value: enumeratorData.mark,
                        },
                        {
                            name: ["model"],
                            value: enumeratorData.model,
                        },

                    ]}
                >
                    <Form.Item name="type" label="Тип счётчика" rules={[{ required: true }]}>
                        <Select
                            placeholder="Пожалуйста, выберите тип счётчика"
                            allowClear
                        >
                            <Select.Option value="gvs">ГВС</Select.Option>
                            <Select.Option value="heat">Отопление</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Серийный номер"
                        name="serialNumber"
                        rules={[{
                            required: true,
                            type: 'number',
                            message: 'Пожалуйста введите Серийный номер!'
                        }]}
                        style={{width: '100%'}}
                    >
                        <InputNumber style={{width: '100%'}}/>
                    </Form.Item>

                    <Form.Item
                        label="Марка"
                        name="mark"
                        rules={[{
                            required: true,
                            message: 'Пожалуйста введите номер квартиры!'
                        }]}
                        style={{width: '100%'}}
                    >
                        <Input style={{width: '100%'}}/>
                    </Form.Item>

                    <Form.Item
                        label="Модель"
                        name="model"
                        rules={[{
                            required: true,
                            message: 'Пожалуйста введите номер квартиры!'
                        }]}
                        style={{width: '100%'}}
                    >
                        <Input style={{width: '100%'}}/>
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                        >
                            Редактировать
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer>
        </div>

    )
}