import React from 'react';
import './Footer.css';
import {Col, Row} from "antd";
import {PhoneOutlined, MailOutlined, CompassOutlined} from '@ant-design/icons';

export const MainFooter = props => {

    return(
        <div>
            <Row size={24}>
                <Col xs={24} md={8} xl={8}>
                    <img src={process.env.PUBLIC_URL + '/img/ObgazLogo.png'} alt="Логотип компании" style={{marginBottom: 25}}/>
                    <br />
                    <span>Все права защищены © 2020</span><br />
                    <span>Открытое Акционерное Общество «Обьгаз»</span>
                </Col>
                <Col xs={24} md={8} xl={8}>
                    <h2 style={{color: '#ffffff'}}>Контакты</h2>
                    <PhoneOutlined style={{fontSize: 20, margin: 5}}/>
                    <a href="tel://+7 (3467) 32-65-01" style={{color: '#ffffff', fontWeight: 'bold'}}>+7 (3467) 32-65-01</a><br />
                    <PhoneOutlined style={{fontSize: 20, margin: 5}}/>
                    <a href="tel://+7 (3467) 32-81-60" style={{color: '#ffffff', fontWeight: 'bold'}}>+7 (3467) 32-81-60</a><br />
                    <MailOutlined style={{fontSize: 20, margin: 5}}/>
                    <b>obgaz@obgaz.ru</b><br/>
                    <CompassOutlined style={{fontSize: 20, margin: 5}}/>
                    г.Ханты-Мансийск, ул.Мира, 120

                </Col>
                <Col xs={24} md={8} xl={8}>
                    <h2 style={{color: '#ffffff'}}>Способы оплаты</h2>
                    <img src={process.env.PUBLIC_URL + '/img/jcb.jpg'} style={{width: 60, padding: 5}}/>
                    <img src={process.env.PUBLIC_URL + '/img/mir.jpg'} style={{width: 60, padding: 5}}/>
                    <img src={process.env.PUBLIC_URL + '/img/master.jpg'} style={{width: 60, padding: 5}}/>
                    <img src={process.env.PUBLIC_URL + '/img/visa.jpg'} style={{width: 60, padding: 5}}/>

                </Col>
            </Row>
        </div>
    )
}