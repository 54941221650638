import axios from "axios";

const AUTH_TOKEN = localStorage.getItem('token') ? localStorage.getItem('token') : ''

const instance =  axios.create({
    baseURL: "https://zero.obgaz.ru/",
    responseType: "json",
    headers: {
        'Authorization': AUTH_TOKEN
    }
})

export default instance