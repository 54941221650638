import React from 'react';
import {withRouter} from 'react-router-dom';
import instance from "../../../../Utils/API";
import {
    Button,
    Card,
    Col,
    Divider,
    Drawer,
    Form,
    Input, notification,
    PageHeader, Popconfirm,
    Row,
    Skeleton, Space,
    Spin,
    Table,
    Tag,
    Tooltip
} from "antd";
import {DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import MDEditor from "@uiw/react-md-editor";
import moment from "moment";
import AdminEditServicesAdd from "./AdminEditServices/AdminEditServicesAdd";

class AdminServices extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            servicesList: [],
            drawerVisible: false,
        }


        const self = this;
        instance.get('/api/dynamicPages/services')
            .then(function (response) {
                self.setState({servicesList: response.data.servicesList})
            })
    }


    getContent = () => {
        const self = this;
        instance.get('/api/dynamicPages/services')
            .then(function (response) {
                self.setState({servicesList: response.data.servicesList})
            })
    }
    render() {
        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: 'Название',
                dataIndex: 'title',
                key: 'title',
            },
            {
                title: 'Дата создания',
                dataIndex: 'creationTime',
                key: 'creationTime',
                render: (item) => {
                    return(
                        <Tag color="green">
                            {moment(item).format('DD.MM.YYYY')}
                        </Tag>
                    )
                }
            },
            {
                title: 'Посследнее обновление',
                dataIndex: 'updateTime',
                key: 'updateTime',
                render: (item) => {
                    return(
                        <Tag color="magenta">
                            {moment(item).format('DD.MM.YYYY')}
                        </Tag>
                    )
                }
            },
            {
                title: 'Действия',
                key: '1',
                render: (item, record) => {
                    return(
                        <Space>
                            <Tooltip title="Редактирование">
                                <Button type="primary" shape="circle" icon={<EditOutlined />} onClick={() => {this.props.history.push(`/services/${record.id}`)}}/>
                            </Tooltip>
                            <Tooltip title="Удаление">
                                <Popconfirm title="Вы уверены?"
                                            okText="Да"
                                            cancelText="Нет"
                                            onConfirm={() => {
                                                const self = this;
                                                instance.delete(`/api/dynamicPages/services/${record.id}`)
                                                    .then(function (response) {
                                                        notification['success']({
                                                            message: 'Услуга успешно удалена',
                                                            description:
                                                                'Спасибо, что воспользовались нашим сервисом!',
                                                        });
                                                        self.getContent();
                                                    })
                                                    .catch(function (error) {
                                                        notification['error']({
                                                            message: error.response.data.error,
                                                            description:
                                                                'Спасибо, что воспользовались нашим сервисом!',
                                                        });
                                                    })
                                            }}
                                >
                                    <Button type="primary" danger shape="circle" icon={<DeleteOutlined />} />
                                </Popconfirm>
                            </Tooltip>
                        </Space>
                    )
                }
            },
        ]


        if(this.state.servicesList.length === 0){
            return (
                <Row align="middle" justify="center">
                    <Spin tip="Загружаем данные..." />
                </Row>
            )
        } else {
            return (
                <div>
                    <PageHeader
                        title="Услуги"
                        extra={[
                            <Tooltip title="Добавить новость">
                                <Button type="primary" icon={<PlusOutlined />} onClick={() => {this.setState({drawerVisible: true})}} />
                            </Tooltip>
                        ]}
                    />
                    <Table dataSource={this.state.servicesList} columns={columns} />

                    <Drawer
                        title="Добавление Услуги"
                        placement="right"
                        closable={false}
                        onClose={() => this.setState({drawerVisible: false})}
                        visible={this.state.drawerVisible}
                        width = {1200}
                    >
                        <AdminEditServicesAdd getContent={this.getContent}/>
                    </Drawer>
                </div>
            )
        }
    }

}

export default withRouter(AdminServices)