import React from 'react';
import {
    Layout,
    Menu,
    Breadcrumb,
    Row,
    Col,
    Card,
    Divider,
    PageHeader,
    Button,
    Drawer,
    Alert,
    notification,
    Spin
} from 'antd';
import {
    PlusCircleOutlined
} from '@ant-design/icons';
import {Enumerators} from "./Enumerators/Enumerators";
import {withAuthUser, withSignOut} from 'react-auth-kit'
import ExcelToJson from "../../../../Utils/ExcelToJson";
import AdminStats from "./AdminStats/AdminStats";
import {AdminCharts} from "./AdminCharts/AdminCharts";
import instance from "../../../../Utils/API";
import {BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import {MobileEnumerators} from "./Enumerators/MobileEnumerators";
import {NoticeBar} from "antd-mobile";
import UserCharts from "./UserCharts/UserCharts";
import moment from "moment";

class UserProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: '',
            loading: true,
        }
        const self = this;
        instance.get(`/api/user/additionalData/${props.authState.jti}`)
            .then(function (response) {
                self.setState({userData: response.data.userAdditionalData, loading: false})
            })
            // .catch(function (error) {
            //     console.log(error)
            //     if(error.response.data !== undefined){
            //         notification['error']({
            //             message: error.response.data.error,
            //             description:
            //                 'Спасибо, что воспользовались нашим сервисом!',
            //         });
            //     }
            // })
    }


    getUserData = () => {
        const self = this;
        instance.get(`/api/user/additionalData/${this.props.authState.jti}`)
            .then(function (response) {
                self.setState({userData: response.data.userAdditionalData, loading: false})
            })
    }

    render() {
        if(this.props.authState.role[0] === 'user' && this.state.loading === false){
            return(
                <div>
                    <BrowserView>
                        {new Date().getDate() > 25 &&
                            <Alert message="Внимание! Внесенные показания с 25 числа по последнее число месяца сохраняться не будут!"
                                   type="warning" showIcon closable
                            />
                        }

                        <Divider orientation="left" plain>
                            <h1>Счетчики</h1>
                        </Divider>
                        <Enumerators userEnumerators={this.state.userData.enumeratorList} getUserData={this.getUserData}/>
                        <UserCharts enumeratorData={this.state.userData}/>
                        <div style={{display: 'flex',}}>
                            <Alert message={`Ваш баланс: ${this.state.userData.balance.balance} ₽`}
                                   description={`Последний платеж: ${moment(this.state.userData.balance.lastTimeUpdated).format('DD.MM.YYYY')}`}
                                   type={this.state.userData.balance.balance > 0 ? 'error' : 'info'} />

                        </div>
                    </BrowserView>
                    <MobileView>
                        <MobileEnumerators userEnumerators={this.state.userData} getUserData={this.getUserData}/>
                    </MobileView>
                </div>
            )
        }
        if(this.props.authState.role[0] === 'user' && this.state.loading === true){
            return (
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Spin tip="Загружаем данные..."/>
                </div>
            )
        }
        if(this.props.authState.role[0] === 'admin' || this.props.authState.role[0] === 'moderator'){
            return (
                <div>
                    <PageHeader
                        className="site-page-header"
                        title="Дашборд"
                        extra={[

                        ]}
                    />

                    <AdminStats />

                    {/*<AdminCharts />*/}
                </div>
            )
        }

    }
}

export default withSignOut(withAuthUser(UserProfile))