import React from "react";

import { Editor } from '@tinymce/tinymce-react';
import {Button, Col, Form, Input, notification, Row} from "antd";
import instance from "../../../../../Utils/API";
import JobWithFiles from "../../../../../Utils/JobWithFiles";


export default function AdminEditServicesAdd(props) {
    const editorRef = React.useRef(null);
    const [title, setTitle] = React.useState('')
    const [imagePath, setImagePath] = React.useState('')
    const [preview, setPreview] = React.useState('')
    const [author, setAuthor] = React.useState('')

    const saveService = () => {
        if (editorRef.current) {
            const content = editorRef.current.getContent()
            instance.post('/api/dynamicPages/services',
                {
                    content: content,
                    title: title,
                    author: author,
                    imagePath: imagePath,
                    preview: preview,
                }
            )
                .then(function (response) {
                    notification["success"]({
                        message: `${response.data.message}`,
                        description:
                            'Спасибо, что воспользовались нашим сервисом!',
                    })
                    return(
                        props.getContent()
                    )
                })
                .catch(function (error) {
                    return(
                        notification["error"]({
                            message: `${error.response.data.error}`,
                            description:
                                'Спасибо, что воспользовались нашим сервисом!',
                        })
                    )
                })
        }
    };


    return (
        <div>
            <Row justify="end">
                <JobWithFiles />
            </Row>
            <div className="site-card-border-less-wrapper">
                <Form
                    name="basic"
                    initialValues={{ remember: true }}
                    layout={"vertical"}
                >
                    <Form.Item
                        label="Название"
                        name="title"
                    >
                        <Input onChange={(e) => setTitle(e.target.value)}/>
                    </Form.Item>
                    <Form.Item
                        label="Изображение"
                        name="imagePath"
                    >
                        <Input onChange={(e) => setImagePath(e.target.value)}/>
                    </Form.Item>
                    <Form.Item
                        label="Краткое описание"
                        name="preview"
                    >
                        <Input onChange={(e) => setPreview(e.target.value)}/>
                    </Form.Item>
                    <Form.Item
                        label="Автор"
                        name="author"
                    >
                        <Input onChange={(e) => setAuthor(e.target.value)}/>
                    </Form.Item>
                </Form>
            </div>
            <Editor
                onInit={(evt, editor) => editorRef.current = editor}
                apiKey="khcsgjtxfdpspdcyqoao18j3grwdn04n1wmk01cj6qoax5uj"
                init={{
                    language: 'ru',
                    skin: 'oxide',
                    language_url: "/langs/ru.js",
                    content_css: false,
                    menubar: false,
                    height: 500,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount imagetools media'
                    ],
                    toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image media| removeformat | help',
                }}
            />
            <Row justify="end" style={{marginTop: 20}}>
                <Button type="primary" onClick={saveService}>Сохранить</Button>
            </Row>

        </div>
    );
}